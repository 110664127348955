/* eslint-disable camelcase */

import React from 'react'
import { Alert, Button, Modal } from 'react-bootstrap'
import { useSubmitTenderBidMutation } from '../../../components/api/BuyerApi'
import Spinner from '../../../components/spinner'
import { NOOP } from '../../../components/utils'
import InfoIconSvg from '../../../images/info-sign.svg'

/**
 *
 * !!! Page !!!
 *
 * @param {Object} props
 * @param {boolean} props.openModal
 * @param {Function} props.setOpenModal
 * @returns
 */
const Page = (props) => {
  // props
  const {
    isValid = false,
    lot_number = -1,
    openModal = true,
    setOpenModal = NOOP
  } = props

  /** @type {import('../../../components/api/BuyerApi').SubmitTenderBidMutation} */
  const submitTenderBidRequest = props.submitTenderBidRequest

  // mutations
  const submitTenderBidMutation = useSubmitTenderBidMutation()

  // state
  const [formSubmitted, setFormSubmitted] = React.useState(false)
  const [selectedLabel, setSelectedLabel] = React.useState('')

  React.useEffect(() => {
    const totalBids = Object.values(submitTenderBidRequest.bids)
    const placedBids = totalBids.filter(b => parseInt(b) > 0)
    if (totalBids.length !== placedBids.length) {
      setSelectedLabel(` (${placedBids.length}/${totalBids.length})`)
    } else {
      setSelectedLabel('')
    }
  }, [submitTenderBidRequest])

  // functions
  const onHide = () => {
    setOpenModal(false)
    setFormSubmitted(false)
    submitTenderBidMutation.reset()
  }
  const internalOnSave = () => {
    if (isValid) {
      setFormSubmitted(true)
      submitTenderBidMutation.mutate(submitTenderBidRequest)
    }
  }

  return <>

        <Modal show={openModal} onHide={onHide}>
            <Modal.Body>
                <h5 className="m-0 mb-3"><img src={InfoIconSvg} className="mr-3" />{isValid ? `Submit bids for Lot ${lot_number}?` : 'Unable to submit' }</h5>

                { isValid
                  ? <p>Bids can be amended up until the time remaining{selectedLabel}.</p>
                  : <p>To submit, place bids on all items.</p>
                }

                {/* buttons */}
                <div className="d-flex justify-content-between mt-5">
                    <Button type="button" onClick={onHide} variant="outline-primary">Cancel</Button>
                    <Button type="button" onClick={internalOnSave} variant="primary" disabled={!isValid || formSubmitted}>
                        {submitTenderBidMutation.isLoading ? <Spinner /> : <span>Submit</span>}
                    </Button>
                </div>

                { submitTenderBidMutation.isSuccess && <Alert variant="success" className="mb-0 mt-3 text-center">Submit successful!</Alert> }
                { submitTenderBidMutation.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'Error submitting bids. ' + (submitTenderBidMutation.error.response.data.message || submitTenderBidMutation.error.message)}</Alert> }

                {process.env.NODE_ENV !== 'production' && <pre>formSubmitted={JSON.stringify(formSubmitted, null, 2)}</pre>}
                {process.env.NODE_ENV !== 'production' && <pre>lot_number={JSON.stringify(lot_number, null, 2)}</pre>}
                {process.env.NODE_ENV !== 'production' && <pre>isValid={JSON.stringify(isValid, null, 2)}</pre>}
                {process.env.NODE_ENV !== 'production' && <pre>submitTenderBidRequest={JSON.stringify(submitTenderBidRequest, null, 2)}</pre>}
            </Modal.Body>
        </Modal>

      </>
}

export default Page
